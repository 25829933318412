.testimonial{
  background-color: #1f3442;
  color: #fff;
  height: 500px;
}



.carousel-temoignage{
  max-height: 400px;
}

.tegmoignagei{
  width: 100%;
  height: fit-content !important;
}

.tegmoignagei .img-responsive{
  width: 150px !important;
  height: 150px !important;
  border: 5px solid #fff;
}

.tegmoignagei .caption-responsive{

}
.tegmoignagei  .temoignage-left{
display: block;
float: left;
width: 70%;
}
.temoignage-righ{
  display: block;


}
.text-temoignage{
  display: inline;
  width: 90%;
  margin-right: 40px;
  line-height: 1cm;
  padding: 5px;
  font-style: italic;
  font-size: larger;
}
.caption-responsive h4{
  color: #fff;
}
.caption-responsive h6{
  color: #fff;

}
.carousel-control-prev,
  .carousel-control-next{
  visibility: hidden;
}



@media (max-width: 2000px) {
  .text-temoignage{
    line-height: 0.5cm;
    font-size: 12px;
    display: block;
    margin: auto;
  }
  .caption-responsive h3{
    font-size: 12px;
    font-weight: 600;
  }
  .caption-responsive h5{
    font-size: 10px;
    font-weight: 500;
  }
  .tegmoignagei  .temoignage-left{
    display: block;
    margin: auto;
    width: 95%;
  }
  .text-title{
    margin:0.5rem;
    font-size: 14px;
  }
}
@media (max-width: 900px) {
  .testimonial{
    height: 600px;
  }
  .text-temoignage{
    line-height: 0.5cm;
    font-size: 10px;
    display: block;
    margin: auto;
  }
  .caption-responsive h3{
    font-size: 12px;
    font-weight: 600;
  }
  .caption-responsive h5{
    font-size: 10px;
    font-weight: 500;
  }
  .tegmoignagei  .temoignage-left{
    display: block;
    margin: auto;
    width: 95%;
  }
  .text-title{
    margin:0.5rem;
    font-size: 11px;
  }
}