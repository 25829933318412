
 .titiititi{
  vertical-align: middle;
  text-transform: uppercase;
  color: #2c3e90;

 }

 .titi1{
  font-size: 25px;
 }
  .fast-visa{
    margin-bottom: 10px;
    border: 1px solid #2c3e90;
    padding: 10px;
  }



  .fa-service{
    width: 50px;
    height: 50px;
    color: #2c3e90;
    margin-bottom: 10px;
  }
.fast-service1{
  width: 90%;
  margin: auto;
}
  .service-titre h3 {
    margin-top: 50px;
    font-size:30px;
    font-weight:700; 
    letter-spacing:10px;
    text-transform:uppercase; 
    margin: auto;
    text-align:center; 
    white-space:nowrap;
    padding-bottom:13px;
    color: #202e6e;
  }

  @media screen and (max-width: 600px) {
    .service-titre h3 {
      font-size: 15px;
    }
  }