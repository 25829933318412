


.jumbotrons {
    background-image: url('../media/banière.png');
    background-size: 100%;
    background-repeat: no-repeat;
    color: #fff;
    height: 500px; 
  }

.fast-titre{
    font-weight: 1000;
    position: relative;
    text-align: center;
}

.section-title{
    text-align: center;
    padding-bottom: 30px;
}

.section-title h2{
    font-size: 32px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative;
    color: #2178bb;

}

.section-title h2::before{
    content: "";
    position: absolute;
    display: block;
    width: 120px;
    height: 1px;
    background-color: #ddd;
    bottom: 1px;
    left: calc(50% - 60px);
}
.section-title h2::after{
    content: "";
    position: absolute;
    display: block;
    width: 40px;
    height: 3px;
    background-color: #2178bb;
    bottom: 0;
    left: calc(50% - 20px);
}
.about-fast{
    text-align: center;
    line-height: 30px;
    font-style: oblique;
    margin: 10px;
}


h1, h2, h3, h4, h5, h6 {
    color: #2c3145;
}
a, a:hover, a:focus, a:active {
    text-decoration: none;
    outline: none;
}
ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.section_all {
    position: relative;
    padding-top: 80px;
    padding-bottom: 80px;
    min-height: 100vh;
}
.section-title {
    font-weight: 700;
    text-transform: capitalize;
    letter-spacing: 1px;
}

.section-subtitle {
    letter-spacing: 0.4px;
    line-height: 28px;
    max-width: 550px;
}

.section-title-border {
    background-color: #000;
    height: 1 3px;
    width: 44px;
}

.section-title-border-white {
    background-color: #fff;
    height: 2px;
    width: 100px;
}
.text_custom {
    color: #00bd2a;
}

.about_icon i {
    font-size: 22px;
    height: 65px;
    width: 65px;
    line-height: 65px;
    display: inline-block;
    background: #fff;
    border-radius: 35px;
    color: #00bd2a;
    box-shadow: 0 8px 20px -2px rgba(158, 152, 153, 0.5);
}

.about_header_main .about_heading {
    max-width: 450px;
    font-size: 24px;
}

.about_icon span {
    position: relative;
    top: -10px;
}

.about_content_box_all {
    padding: 28px;
}
.img_about{
    height: 300px;
    width: 500px;
}
.image-folefack{
    height: 300px;
    width: 300px;
    border: 3px solid lawngreen;
}




#container{
    width: 80%;
    min-height: 1050px;
    position: relative;
    font-family: 'Noto Sans HK', sans-serif;
    left: 10%;

}
#heading{
    width: 97%;
    height: 80px;
    background-color: cadetblue;
    color: white;
    top: -20px;
    position: relative;
}
#heading h1{
    font-family: 'Noto Sans HK', sans-serif;
    text-align: center;
    top: 25px;
    position: relative;
}
.team{
    width: 98%;
    height: 330px;
    border-radius: 8px;
}

#container .item{
    overflow: hidden;
    width: 33.33335%;
    float: left;
    height: 330px;
    margin-bottom: 20px;
    cursor: pointer;
    border-radius: 8px;
}
.front{
    backface-visibility: hidden;
    transform: perspective(600px) rotateX(0deg);
    transition: 1.5s;
    overflow: hidden;
    backface-visibility: hidden;
    border-radius: 8px;
    background: lime;
}
.back{
    width: 97%;
    height: 330px;
    overflow: hidden;
    background-color:#2178bb;
    position: relative;
    top: -350px;
    transform: perspective(600px) rotateX(180deg);
    transition: 1.5s;
    overflow: hidden;
    backface-visibility: hidden;
    box-shadow: 0 0 35px #007BB6;
    border-radius: 8px;
}
.title-name{
    position: relative;
    top: 110px;
    text-align: center;
    font-size: 30px;
    color: #fff;
}
.job{
    position: relative;
    top: 90px;
    text-align: center;
    font-size: 25px;
    color: #fff;
}
@keyframes move{
    0%{
        position: relative;
        top: 0px;
    }
    100%{
        position: relative;
        top: 80px;
    }
}
.social{
    position: relative;
    top: 90px;
    color: black;
    margin-right: 10px;
    justify-content: center;
    align-content: center;
    align-items: center;
    position: relative;
    left: 34%;
}
.fa-facebook:hover{
    color: #3B558D;
}
.fa-linkedin:hover{
    color: #007BB6;
}
.fa-twitter:hover{
    color: #2CAAE1;
}
#container .item:hover > .front{
    transform: perspective(600px) rotateX(-180deg);
}
#container .item:hover > .back{
    transform: perspective(600px) rotateX(0deg);
}
@media screen and (max-width: 980px) {
    .tango{
        display: block;
        width: 95% !important;
        
    }
    #container{
        width: 90%;
        min-height: 2450px;

    
    }

}


