#root{
    width: 100%;
}

.maiva-acer{
    border: 5px solid #fff !important;
    margin: 0%;
}
.maiva-idriss{
    margin-top: 30px;
    padding: 20px;
    background:  #2178bb;
    color: #fff;
}

.contact-titre{
    font-size: 10vw;
    color: #2178bb;
    margin: auto;

}
.fa-contact{
    font-size: 10px;
    color: #2178bb;
}

.dbox .icon {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: #fff;
    margin: 0 auto;
    margin-bottom: 20px;
}
.titlea{
    text-transform: uppercase;
    margin-top: 30px;
    font-size:2vw;
    font-weight: 600;
    background-image: linear-gradient(to left, #2c3e90, #2178bb);
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text
}

.text a{
    color: #000;
}


