.question-section{
    width: 95%;
    margin: auto;
   
}
.question-mark{
    font-size: 100px;
    width: 100%;
    color: rgb(44, 62, 144,1);
    margin-bottom: 5px;
    font-weight: bolder;
}

.pe-2{
    font-size: 30px;
}

.row p, .row h6{
    text-align: center;
}

