


.link-fast{
    position: fixed;
    background-color:#25d366;
    width:60px;
	height:60px;
	bottom:40px;
	right:40px;
	color:#FFF;
	border-radius:50px;
	text-align:center;
    font-size:30px;
	box-shadow: 2px 2px 3px #999;
    z-index: 100;

}


.link-fast:hover{
    background-color: rgb(44, 62, 144,1);
}