@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
}
body{
    width: 100%;
    min-height: 100vh;
    display: grid;
    place-items: center;
    font-family: 'Poppins', sans-serif;
}
.aboutconatainer{
    width: 100%;
    height: 100%;
    max-width: 1170px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    grid-gap: 60px;
    padding: 35px 0;
}
.contentLeft,
.contentRight{
    width: 100%;
}
.contentLeft .row{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
}
.contentLeft .row .imgWrapper{
    width: 100%;
    height: 450px;
    overflow: hidden;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.15);
}
.contentLeft .row .imgWrapper img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    user-select: none;
    transition: 0.3s ease;
}
.contentLeft .row .imgWrapper:hover img{
    transform: scale(1.5);
}
.contentLeft .row .imgWrapper:nth-child(odd){
    transform: translateY(-20px);
}
.contentLeft .row .imgWrapper:nth-child(even){
    transform: translateY(20px);
}
.contentRight .content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
}
.contentRight .content h4{
    font-size: 22px;
    font-weight: 400;
    color: #2178bb;
}
.contentRight .content h2{
    font-size: 40px;
    color: #1e272e;
}
.contentRight .content p{
    text-align: center;
    font-size: 16px;
    color: #343434;
    line-height: 28px;
    padding-bottom: 10px;
}
.contentRight .content a{
    display: inline-block;
    text-decoration: none;
    font-size: 16px;
    letter-spacing: 1px;
    padding: 13px 30px;
    color: #fff;
    background: #2178bb;
    border-radius: 8px;
    user-select: none;
    margin: auto;
}
.contentRight .content a:hover{
    opacity: 0.5;
}
.text2 {
    margin-top: 10px;
    font-size: 45px;
    color: #2c3e90;
    font-weight: 900;
    text-align: center;
  }
  
@media(max-width: 768px){
    .contentRight{
        height: 400px;
    }
    .container{
        grid-template-columns: 1fr;
    }
    .contentLeft .row{
        grid-template-columns: repeat(2, 1fr);
    }
    .contentLeft .row .imgWrapper{
        height: 150px;
    }
    .contentRight .content h4{
        font-size: 18px;
    }
    .contentRight .content h2{
        font-size: 30px;
    }
    .text2{
        margin-top: 5px;
        font-size: 25px;

    }
}


  .container-welcome span {
    text-transform: uppercase;
    display: block; 
  }
  

  

 