
    .logo-footer{
      display: block;
      width: 100px;
      margin: auto;
      background-color: rgb(255, 255, 255,0.5);    
    }
  .col-md-3 {
    border: none !important;
    margin: auto;
  }
    