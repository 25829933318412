.gallerie {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
    grid-column-start: 1;
    grid-row-start: 1;
    grid-row-end: 3;
    align-content: start;
    max-width: 700px;
    margin: 0 auto;
    transition: all 150ms linear;
    
  }

  .gallerie input[type="radio"] {
    display: none;
  }
  
  .gallerie label {
    
    position: relative;
    display: block;
    padding-bottom: 60%;
    margin: 5px;
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
  }
  
  .gallerie label:before {
    border: 1px solid #e3e3e3;
    content: '';
    position: absolute;
    left: -5px;
    right: -5px;
    bottom: -5px;
    top: -5px;
  }
  
  .gallerie img {
    display: none;
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 2;
    width: 100%;
    max-height: 500px !important;
    transition: all 150ms linear;
  }
  
  .gallerie input[name="select"]:checked + label + img {
    display: block;
  }
  
  .gallerie input[name="select"]:checked + label:before {
    border: 1px solid red;
  }